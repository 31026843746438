@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-inter: 'Inter', sans-serif;
}

* {
    scroll-behavior: smooth;
}

@layer base {
    h1 {
        @apply font-extrabold text-[2.5rem] leading-[3.25rem] md:text-[4.5rem] md:leading-[3.75rem] lg:text-[5rem] lg:leading-[4.0625rem] xl:text-[6rem] xl:leading-[4.5rem];
    }

    /* .container {
    @apply max-w-[77.5rem] mx-auto px-5 md:px-10 lg:px-15 xl:max-w-[87.5rem];
  } */

    h2 {
        @apply text-[1.75rem] leading-[2.5rem] md:text-[2rem] md:leading-[2.5rem] lg:text-[2.5rem] lg:leading-[3.5rem] xl:text-[3rem] xl:leading-tight;
    }

    /* h3 {
    @apply text-[2rem] leading-normal md:text-[2.5rem];
  } */

    /* h4 {
    @apply text-[2rem] leading-normal;
  } */

    /* h5 {
    @apply text-2xl leading-normal;
  } */

    /* h6 {
    @apply font-semibold text-lg leading-8;
  } */

    /* body-1 {
    @apply text-[0.875rem] leading-[1.5rem] md:text-[1rem] md:leading-[1.75rem] lg:text-[1.25rem] lg:leading-8;
  } */

    /* body-2 {
    @apply font-light text-[0.875rem] leading-6 md:text-base;
  } */

    /* caption {
    @apply text-sm;
  } */

    /* tagline {
    @apply font-grotesk font-light text-xs tracking-tagline uppercase;
  } */

    /* quote {
    @apply font-code text-lg leading-normal;
  } */

    /* button {
    @apply font-code text-xs font-bold uppercase tracking-wider;
  } */
}

body {
    font-family: var(--font-inter);
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}